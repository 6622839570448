@import url('https://fonts.googleapis.com/earlyaccess/nanumgothic.css');

.App {
  text-align: center;
  font-size: 0.8rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App {
	margin: 0 auto;
	min-width: 200px;
	max-width: 500px;
}
.App > section {
	padding: 20px;
	background-color: rgb(245,245,245);
	border: 1px solid rgb(240,240,240);
	border-radius: 5px;
	margin-bottom: 10px;
}
.NoPadding {
	padding: 0;
}
.slick-slide{
	padding-right: 10px;
}
.slick-slider{
	padding: 0;
}
/* styles.css */
.slide-up-enter {
  transform: translateY(100%);
  opacity: 0;
}
.slide-up-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 500ms, opacity 500ms;
}
.slide-up-exit {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-exit-active {
  transform: translateY(100%);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
}
.notion-header{
  display:none;
}
.notion-title{
  text-align: 'center';
  font-weight:'bold';
  font-size:'1.5rem';
  margin-bottom: '2rem';
}
.notion-page-no-cover{
  margin-top:0 !important;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* 크롬, 사파리 브라우저에서 스크롤바 숨기기 */
}
